import React from "react";
import Giscus from "@giscus/react";

export const GiscusComment = () => {
  return (
    <Giscus
      id="giscus-comments"
      repo="abelce/giscus-comments-favicon"
      repoId="R_kgDOMzm8-g"
      category="Announcements"
      categoryId="DIC_kwDOMzm8-s4CilLO"
      mapping="url"
      term="Welcome to @giscus/react component!"
      reactionsEnabled="0"
      emitMetadata="0"
      inputPosition="top"
      theme="light"
      lang="zh-CN"
    />
  );
};